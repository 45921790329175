import React from "react";
import delIcon from "../../../assests/delIcon.png";
import LoaderSpinner from "../../../util/LoaderSpinner";
const DeleteChat = ({
  setShow,
  type,
  action,
  loading,
  handleClick
}) => {
  return (
    <>
      <div className="text-center p-5">
        <div className="flex justify-center items-center">
          <img src={delIcon} alt="delicon" className="object-cover" />
        </div>
        <div className="py-5">
          <h1 className="text-[#292929] text-[20px] font-semibold">
            {type}
          </h1>
          <div className="pt-5">
            <button
              onClick={() => setShow(false)}
              className="w-[138px] py-2 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]"
            >
              Cancel
            </button>
            <button
              onClick={() => handleClick()}
              className={`w-[138px] lg:my-0 md:my-0 my-2  py-2 lg:ml-3 rounded-md ${action === "remove" ? 'bg-[#F04438]' : 'bg-[#FF8C00]'} text-white`}
            >
              {loading ? <div className="flex justify-center items-center"><LoaderSpinner type="weight" /></div> : action === "remove" ? "Delete" : "Unflag"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteChat;
