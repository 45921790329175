import React, { useState } from "react";
import LoaderSpinner from "../../../util/LoaderSpinner";
import { Truncate } from "../../../util/TrucateString";
import { collection, deleteDoc, doc, getDocs, query, updateDoc } from "firebase/firestore";
import { db } from "../../../confiq/firebase";
import { FaTrash } from "react-icons/fa";
import DeleteChat from "./DeleteChat";
import Popup from "../../../util/popup/Popup";
import moment from "moment/moment";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import ReactPaginate from "react-paginate";
import EditView from "./EditView";
import { Link } from "react-router-dom";

const FlagChatList = ({ messages, loading, searchData }) => {
  // React hooks
  const [loadingAction, setLoadingAction] = useState(false)
  const [show, setShow] = useState(false)
  const [type, setType] = useState("")
  const [action, setAction] = useState("")
  const [selected, setSelected] = useState({})
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + 10;
  const currentItems = messages.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(messages.length / 10);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % messages.length;
    setItemOffset(newOffset);
  };

  const openPopup = (e, item, mode) => {
    e.stopPropagation();
    setSelected(item)
    setShow(true);
    setType(mode);
    setAction(mode)
  };


  const handleRemove = async () => {
    setLoadingAction(true)
    setType("remove")
    try {
      const senderDocRef = doc(
        db,
        `Users/${selected.senderId}/Conversation/${selected.conversationId}/Messages/${selected.messageId}`
      );
      const receiverDocRef = doc(
        db,
        `Users/${selected.receiverId}/Conversation/${selected.conversationId}/Messages/${selected.messageId}`
      );
      const FlagDocRef = doc(
        db,
        `FlaggedMessages/${selected?.id}`
      );
      await deleteDoc(senderDocRef);
      await deleteDoc(receiverDocRef);
      await deleteDoc(FlagDocRef);
      setLoadingAction(false)
      setShow(false)
    } catch (error) {
      console.error('Error deleting messages:', error);
    }
  };

  const handleUnFlage = async () => {
    setLoadingAction(true);
    setType("flag");
    try {
      const senderMessagesQuery = query(
        collection(
          db,
          `Users/${selected.senderId}/Conversation/${selected.conversationId}/Messages`
        )
      );
      const receiverMessagesQuery = query(
        collection(
          db,
          `Users/${selected.receiverId}/Conversation/${selected.conversationId}/Messages`
        )
      );
      // Get all messages for sender and receiver
      const senderMessagesSnapshot = await getDocs(senderMessagesQuery);
      const receiverMessagesSnapshot = await getDocs(receiverMessagesQuery);
      const senderMessages = senderMessagesSnapshot.docs.map(doc => doc.data());
      const receiverMessages = receiverMessagesSnapshot.docs.map(doc => doc.data());
      // Sort the messages by message time (assuming `messageTime` is in a sortable format)
      senderMessages.sort((a, b) => new Date(b.messageTime) - new Date(a.messageTime));
      receiverMessages.sort((a, b) => new Date(b.messageTime) - new Date(a.messageTime));
      if (senderMessages.length > 1) {
        const senderDocRef = doc(
          db,
          `Users/${selected.senderId}/Conversation/${selected.conversationId}/Messages/${selected.messageId}`
        );
        await updateDoc(senderDocRef, { isFlag: false });
        // Check if the flagged message is the latest
        if (selected.messageId === senderMessages[0].messageId) {
          const lastMessageText = selected.message || '';
          const lastMessageTime = selected.messageTime || '';
          const lastMessageId = selected.messageId || '';

          if (lastMessageText && lastMessageTime && lastMessageId) {
            const SenderconversationDocRef = doc(
              db,
              `Users/${selected.senderId}/Conversation/${selected.conversationId}`
            );
            await updateDoc(SenderconversationDocRef, {
              lastMessage: lastMessageText,
              lastMessageTime: lastMessageTime,
              lastMessageId: lastMessageId,
            });
          }
        } else {
          const lastSenderMessage = senderMessages[0];
          const lastMessageText = lastSenderMessage?.message || '';
          const lastMessageTime = lastSenderMessage?.messageTime || '';
          const lastMessageId = lastSenderMessage?.messageId || '';

          if (lastMessageText && lastMessageTime && lastMessageId) {
            const SenderconversationDocRef = doc(
              db,
              `Users/${selected.senderId}/Conversation/${selected.conversationId}`
            );
            await updateDoc(SenderconversationDocRef, {
              lastMessage: lastMessageText,
              lastMessageTime: lastMessageTime,
              lastMessageId: lastMessageId,
            });
          }
        }
      }
      if (receiverMessages.length > 1) {
        const receiverDocRef = doc(
          db,
          `Users/${selected.receiverId}/Conversation/${selected.conversationId}/Messages/${selected.messageId}`
        );
        await updateDoc(receiverDocRef, { isFlag: false });
        // Check if the flagged message is the latest
        debugger
        if (selected.messageId === receiverMessages[0].messageId) {
          const lastMessageText = selected.message || '';
          const lastMessageTime = selected.messageTime || '';
          const lastMessageId = selected.messageId || '';

          if (lastMessageText && lastMessageTime && lastMessageId) {
            const ReciverconversationDocRef = doc(
              db,
              `Users/${selected.receiverId}/Conversation/${selected.conversationId}`
            );
            await updateDoc(ReciverconversationDocRef, {
              lastMessage: lastMessageText,
              lastMessageTime: lastMessageTime,
              lastMessageId: lastMessageId,
            });
          }
        } else {
          const lastReceiverMessage = receiverMessages[0];
          const lastMessageText = lastReceiverMessage?.message || '';
          const lastMessageTime = lastReceiverMessage?.messageTime || '';
          const lastMessageId = lastReceiverMessage?.messageId || '';

          if (lastMessageText && lastMessageTime && lastMessageId) {
            const ReciverconversationDocRef = doc(
              db,
              `Users/${selected.receiverId}/Conversation/${selected.conversationId}`
            );
            await updateDoc(ReciverconversationDocRef, {
              lastMessage: lastMessageText,
              lastMessageTime: lastMessageTime,
              lastMessageId: lastMessageId,
            });
          }
        }
      }
      const FlagDocRef = doc(db, `FlaggedMessages/${selected.id}`);
      await deleteDoc(FlagDocRef);
      setLoadingAction(false);
      setShow(false);
    } catch (error) {
      console.error('Error updating or deleting messages:', error);
    }
  };


  const filteredData = currentItems.filter((item) =>
    item?.senderDetails.userName?.toLowerCase().includes(searchData?.toLowerCase())
    || item?.receiverDetails?.userName?.toLowerCase().includes(searchData?.toLowerCase())
    || item?.message?.toLowerCase().includes(searchData?.toLowerCase()))

  return (
    <>
      {
        show &&
        <Popup
          modalOpen={show}
          onClose={() => setShow(false)}
          data={type === "view" || type === "profile" ? <EditView list={selected} setShow={setShow} type={type} /> : <DeleteChat loading={loadingAction} handleClick={type === "remove" ? handleRemove : handleUnFlage} setShow={setShow} action={action} type={type === "remove" ? " Do you want to delete this message" : " Do you want to UnFlag this"} />} />
      }
      <div className="bg-white rounded-md shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)]">
        <div className="overflow-x-auto bg-white rounded-md mt-6 w-full">
          {messages?.length > 0 ? (
            <>
              <table className="table-auto w-full ">
                {/* Table header */}
                <thead className="text-xs font-[500] bg-[#FFF8EF] border-0">
                  <tr className="text-[#626973]">
                    <th className="px-5 py-4 whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-left">
                        Date/Time
                      </div>
                    </th>
                    <th className="px-5 py-4 whitespace-nowrap w-px">
                      <div className="font-[500] text-[14px] text-left">Sender</div>
                    </th>
                    <th className="px-5 py-4 whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-left">
                        Reciever
                      </div>
                    </th>
                    <th className="px-5 py-4 whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-left">
                        Message
                      </div>
                    </th>
                    <th className="px-5 py-4 whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-left">
                        View
                      </div>
                    </th>
                    <th className="px-5 py-4 whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-center">
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm ">
                  {!loading ? (
                    filteredData?.map((item, i) =>

                      <tr className=" cursor-pointer" key={i}>
                        <td className="px-5 py-4 whitespace-nowrap">
                          <div className="text-left text-[14px] text-[#898989]">
                            {moment(item?.messageTime).format('MM/DD/YYYY HH:mm')}
                          </div>
                        </td>
                        <td
                          className="px-2 first:pl-5 flex items-center last:pr-5 py-3 whitespace-nowrap"
                        >
                          <Link to={`/dinss/tech/vehicalRenters/detail/${item?.senderDetails?.userId}/all`} className="text-left text-[14px] text-[#898989]  flex items-center">
                            <div className="object-cover w-8 h-8 rounded-full overflow-hidden flex items-center justify-center">
                              <img
                                src={item?.senderDetails?.userImage}
                                alt="rent_image"
                                className="w-auto"
                              />
                            </div>
                            <div className="text-left  text-[14px] text-[#898989] px-1">
                              {item?.senderDetails?.userName}
                            </div>
                          </Link>
                        </td>
                        <td className="px-5 py-4 whitespace-nowrap ">
                          <Link to={`/dinss/tech/vehicalRenters/detail/${item?.receiverDetails?.userId}/all`} className="text-left text-[14px] text-[#898989]  flex items-center">
                            <div className="object-cover w-8 h-8 rounded-full overflow-hidden flex items-center justify-center">
                              <img
                                src={item?.receiverDetails?.userImage}
                                alt="rent_image"
                                className="w-auto"
                              />
                            </div>
                            <div className="text-left  text-[14px] text-[#898989] px-1">
                              {item?.receiverDetails?.userName}
                            </div>
                          </Link>
                        </td>
                        <td className="px-5 py-4">
                          <div className="text-left text-[14px] text-[#898989]">
                            {Truncate(item?.message, 150)}
                            {/* <TruncateText text={item?.message} maxLength={40} /> */}
                          </div>
                        </td>
                        <td className="px-5 py-4">
                          <button onClick={(e) => openPopup(e, item, "view")} className="bg-[#FF8C00] text-white text-center px-6 py-2 rounded-md">
                            View
                          </button>
                        </td>
                        <td className="px-5 py-4 whitespace-nowrap">
                          <div className="flex justify-center gap-5">
                            <div className=" pt-2">
                              <button onClick={(e) => openPopup(e, item, "flag")} className="bg-[#FF8C00] text-white text-center px-3 py-2 rounded-md">
                                Remove Flag
                              </button>
                            </div>
                            <div className=" pt-2">
                              <button onClick={(e) => openPopup(e, item, "remove")} className="bg-red-500 text-white  text-center px-3 py-2 rounded-md">
                                <div className="flex items-center gap-2">
                                  <FaTrash className="text-white" />
                                  Delete
                                </div>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  ) : (
                    <div className="p-5 flex items-center">
                      <LoaderSpinner type="tableLoad" />
                    </div>
                  )}
                </tbody>
              </table>
            </>
          ) : (
            <div className="p-5 flex items-center px-3 py-3">
              {loading ? (
                <LoaderSpinner type="tableLoad" />
              ) : (
                <h2>Record not found</h2>
              )}
            </div>
          )}
        </div>
        {messages?.length > 0 && (
          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center pt-6 ">
            <div className="px-8 xxtra-small:px-0 mt-4 mb-1 w-full flex justify-end xxtra-small:justify-start">
              <ReactPaginate
                breakLabel="..."
                breakClassName=" bg-[#F3F3F3]"
                nextLabel={<MdOutlineKeyboardArrowRight />}
                onPageChange={handlePageClick}
                activeClassName="bg-[#FF8C00] text-white rounded"
                nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
                previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<MdOutlineKeyboardArrowLeft />}
                className=" flex justify-end  xxtra-small:w-full xxtra-small:justify-start xtra-small:justify-start xxtra-small:flex-wrap xtra-small:flex-wrap"
                pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] xxtra-small:mb-4 xtra-small:mb-4"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FlagChatList;
