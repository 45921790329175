import { FaUsers } from "react-icons/fa";

import hom from "../../../assests/iconhome.svg";
// import icon1 from "../../../assests/icon1.svg";
// import icon2 from "../../../assests/icon2.svg";
import icon3 from "../../../assests/icon3.svg";
import icon4 from "../../../assests/icon4.svg";
import icon5 from "../../../assests/icon5.svg";
import icon6 from "../../../assests/icon6.svg";
import icon7 from "../../../assests/icon7.svg";
import icon8 from "../../../assests/icon8.svg";
import icon9 from "../../../assests/icon9.svg";
import icon11 from "../../../assests/icon11.svg";
import icon17 from "../../../assests/icon17.svg";
// import icon15 from "../../../assests/icon15.svg";
import icon16 from "../../../assests/icon16.svg";
import icon18 from "../../../assests/icon18.svg";
// import icon19 from "../../../assests/sales.svg";
import notifi from "../../../assests/broadcast 1.png";
import users from "../../../assests/newDesign/users.svg";
import sales from "../../../assests/newDesign/sale.svg";

const SidebarObj = () => {
  return [
    {
      groupname: "DASHBOARD",
      menuitems: [
        {
          label: "Dashboard",
          leftIcon: (
            <img
              src={hom}
              alt="icon"
              className="object-contain w-[24px] h-[24px]  "
            />
          ),
          activeLeftIcon: (
            <img
              src={icon11}
              alt="icon"
              className="object-contain w-[24px] h-[24px]"
            />
          ),
          rightIcon: "sadf",
          path: "/dinss/tech/dashboard",
          pathname: "/dinss/tech/dashboard",
          subMenuItems: [],
        },

        {
          label: "User management",
          leftIcon: (
            <img
              src={users}
              alt="icon"
              className="object-contain w-[24px] h-[24px]"
            />
          ),
          activeLeftIcon: (
            <img
              src={users}
              alt="icon"
              className="object-contain w-[24px] h-[24px]"
            />
          ),
          rightIcon: "sadf",
          path: "/dinss/tech/vehicalRenters",
          pathname: "vehicalRenters",
          subMenuItems: [
            {
              label: "Vehical Renters",
              path: "/dinss/tech/vehicalRenters",
              subMenuItems: [],
            },
            {
              label: "Vehical Owners",
              path: "/dinss/tech/vehicalOwner",
              subMenuItems: [],
            },
            {
              label: "Registered Users",
              path: "/dinss/tech/registerUsers",
              subMenuItems: [],
            },
          ],
        },
        {
          label: "Sales management",
          leftIcon: (
            <img
              src={sales}
              alt="icon"
              className="object-contain w-[24px] h-[24px]w-[50%] h-[50%]"
            />
          ),
          activeLeftIcon: (
            <img
              src={sales}
              alt="icon"
              className="object-contain w-[24px] h-[24px]w-[50%] h-[50%]"
            />
          ),
          rightIcon: "sadf",
          path: "/dinss/tech/vehicalSaller",
          pathname: "vehicalRenters",
          subMenuItems: [
            {
              label: "Vehical Seller",
              path: "/dinss/tech/vehicalSaller",
              subMenuItems: [],
            },
            {
              label: "Vehical Buyer",
              path: "/dinss/tech/vehicalBuyer",
              subMenuItems: [],
            },
          ],
        },
        {
          label: "Customer Report",
          leftIcon: (
            <img
              src={icon3}
              alt="icon"
              className="object-contain w-[24px] h-[24px]  "
            />
          ),
          activeLeftIcon: (
            <img
              src={icon3}
              alt="icon"
              className="object-contain w-[24px] h-[24px]  "
            />
          ),
          rightIcon: "sadf",
          path: "/dinss/tech/customerRenter",
          pathname: "/dinss/tech/customerRenter",
          subMenuItems: [
            {
              label: "Car Renters",
              path: "/dinss/tech/customerRenter",
              subMenuItems: [],
            },
            {
              label: "Car Owners",
              path: "/dinss/tech/customerOwner",
              subMenuItems: [],
            },
          ],
        },

        {
          label: "Global Reporting",
          leftIcon: (
            <img
              src={icon4}
              alt="icon"
              className="object-contain w-[24px] h-[24px]  "
            />
          ),
          activeLeftIcon: (
            <img
              src={icon4}
              alt="icon"
              className="object-contain w-[24px] h-[24px]  "
            />
          ),
          rightIcon: "sadf",
          path: "/dinss/tech/globelRenter",
          pathname: "globelRenter",
          subMenuItems: [
            {
              label: "Car Renters",
              path: "/dinss/tech/globelRenter",
              subMenuItems: [],
            },
            {
              label: "Car Owners",
              path: "/dinss/tech/globelOwner",
              subMenuItems: [],
            },
            {
              label: "KPIs & Metrics",
              path: "/dinss/tech/kpiMatric",
              subMenuItems: [],
            },
          ],
        },
        {
          label: "Broadcast",
          leftIcon: (
            <img
              src={icon5}
              alt="icon"
              className="object-contain w-[24px] h-[24px]  "
            />
          ),
          activeLeftIcon: (
            <img
              src={notifi}
              alt="icon"
              className="object-contain w-[24px] h-[24px]"
            />
          ),
          rightIcon: "sadf",
          path: "/dinss/tech/broadcast",
          pathname: "broadcast",
          subMenuItems: [],
        },
        {
          label: "Pending Transfer",
          leftIcon: (
            <img
              src={icon5}
              alt="icon"
              className="object-contain w-[24px] h-[24px]  "
            />
          ),
          activeLeftIcon: (
            <img
              src={notifi}
              alt="icon"
              className="object-contain w-[24px] h-[24px]"
            />
          ),
          rightIcon: "sadf",
          path: "/dinss/tech/transfer",
          pathname: "transfer",
          subMenuItems: [],
        },
        {
          label: "Primecar Drive",
          leftIcon: (
            <img
              src={icon5}
              alt="icon"
              className="object-contain w-[24px] h-[24px]  "
            />
          ),
          activeLeftIcon: (
            <img
              src={notifi}
              alt="icon"
              className="object-contain w-[24px] h-[24px]  "
            />
          ),
          rightIcon: "sadf",
          path: "",
          pathname: "Orders",
          subMenuItems: [
            {
              label: "Orders",
              path: "/dinss/tech/orders",
              subMenuItems: [],
            },
            {
              label: "Send Code",
              path: "/dinss/tech/sendCode",
              subMenuItems: [],
            },

          ],
        },



        {
          label: "Manage Roles",
          leftIcon: (
            <img
              src={icon6}
              alt="icon"
              className="object-contain w-[24px] h-[24px]  "
            />
          ),
          activeLeftIcon: (
            <img
              src={icon16}
              alt="icon"
              className="object-contain w-[24px] h-[24px]"
            />
          ),
          rightIcon: "sadf",
          path: "/dinss/tech/manageRole",
          pathname: "manageRole",
          subMenuItems: [],
        },

        {
          label: "Flagged Messages",
          leftIcon: (
            <img
              src={icon6}
              alt="icon"
              className="object-contain w-[24px] h-[24px]  "
            />
          ),
          activeLeftIcon: (
            <img
              src={icon16}
              alt="icon"
              className="object-contain w-[24px] h-[24px]"
            />
          ),
          rightIcon: "sadf",
          path: "/dinss/tech/flag",
          pathname: "Flag",
          subMenuItems: [],
        },
        {
          label: "Management",
          leftIcon: (
            <img
              src={icon7}
              alt="icon"
              className="object-contain w-[24px] h-[24px]  "
            />
          ),
          activeLeftIcon: (
            <img
              src={icon17}
              alt="icon"
              className="object-contain w-[24px] h-[24px]"
            />
          ),
          rightIcon: "sadf",
          path: "/dinss/tech/management",
          pathname: "management",
          subMenuItems: [],
        },
        {
          label: "Marketing",
          leftIcon: (
            <img
              src={icon8}
              alt="icon"
              className="object-contain w-[24px] h-[24px]  "
            />
          ),
          activeLeftIcon: (
            <img
              src={icon18}
              alt="icon"
              className="object-contain w-[24px] h-[24px]"
            />
          ),
          rightIcon: "sadf",
          path: "/dinss/tech/afiliate",
          pathname: "marketing",
          subMenuItems: [
            {
              label: "Afiliate Users",
              path: "/dinss/tech/afiliate",
              subMenuItems: [],
            },
            {
              label: "Promocode",
              path: "/dinss/tech/promocode",
              subMenuItems: [],
            },
            {
              label: "Service Fees",
              path: "/dinss/tech/servicefee",
              subMenuItems: [],
            },
          ],
        },
        {
          label: "Customer Support",
          leftIcon: (
            <img
              src={icon9}
              alt="icon"
              className="object-contain w-[24px] h-[24px]  "
            />
          ),
          activeLeftIcon: (
            <img
              src={icon9}
              alt="icon"
              className="object-contain w-[24px] h-[24px]"
            />
          ),
          rightIcon: "sadf",
          path: "/dinss/tech/ticket",
          pathname: "ticket",
          subMenuItems: [
            {
              label: "Tickets",
              path: "/dinss/tech/ticket",
              subMenuItems: [],
            },
            {
              label: "Report",
              path: "/dinss/tech/report",
              subMenuItems: [],
            },
            {
              label: "Chat with users",
              path: "/dinss/tech/chatWithUsers",
              subMenuItems: [],
            },
          ],
        },
      ],
    },
  ];
};
export default SidebarObj;