// import React, { useEffect, useState, useRef } from "react";
// import { MdClose } from "react-icons/md";
// import { collection, onSnapshot, query, orderBy, limit, startAfter } from "firebase/firestore";
// import { db } from "../../../confiq/firebase";
// import moment from "moment";
// import LoaderSpinner from "../../../util/LoaderSpinner";

// const EditView = ({ setShow, list }) => {
//   const [lastVisible, setLastVisible] = useState(null);
//   const [messagesAll, setMessagesAll] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [isFetchingMore, setIsFetchingMore] = useState(false);
//   const [hasMoreMessages, setHasMoreMessages] = useState(true);
//   const ref = useRef(null);
//   const [isUserAtBottom, setIsUserAtBottom] = useState(true);
//   const fetchMessages = async (isScrollFetch = false) => {
//     try {
//       const messagesRef = collection(
//         db,
//         `Users/${list?.senderId}/Conversation/${list?.conversationId}/Messages`
//       );

//       let queryRef = query(messagesRef, orderBy('messageTime', 'desc'), limit(10));

//       if (isScrollFetch && lastVisible) {
//         queryRef = query(queryRef, startAfter(lastVisible));
//       }

//       const newUnsubscribe = onSnapshot(queryRef, (querySnapshot) => {
//         const newMessages = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));

//         if (newMessages.length < 10) {
//           setHasMoreMessages(false);
//         }

//         if (newMessages.length > 0) {
//           setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
//         }

//         setMessagesAll((prev) => {
//           const existingIds = new Set(prev.map((msg) => msg.id));
//           const filteredMessages = newMessages.reverse().filter((msg) => !existingIds.has(msg.id));
//           return [...filteredMessages, ...prev];
//         });

//         setLoading(false);
//         setIsFetchingMore(false);

//         // Auto-scroll to bottom if the user is at the bottom
//         if (isUserAtBottom && ref.current) {
//           ref.current.scrollTop = ref.current.scrollHeight;
//         }
//       });

//       return newUnsubscribe;
//     } catch (error) {
//       console.error('Error fetching messages:', error);
//       setLoading(false);
//       setIsFetchingMore(false);
//     }
//   };

//   const handleScroll = (event) => {
//     const messageContainer = event.target;
//     const isAtBottom =
//       messageContainer.scrollHeight - messageContainer.scrollTop === messageContainer.clientHeight;
//     setIsUserAtBottom(isAtBottom);

//     if (messageContainer.scrollTop === 0 && !isFetchingMore) {
//       const currentScrollHeight = messageContainer.scrollHeight;

//       setIsFetchingMore(true);
//       fetchMessages(true).then(() => {
//         setTimeout(() => {
//           const newScrollHeight = messageContainer.scrollHeight;
//           const scrollHeightDifference = newScrollHeight - currentScrollHeight;
//           if (scrollHeightDifference > 0) {
//             messageContainer.scrollTop = scrollHeightDifference;
//           }
//         }, 150); // Timeout to ensure new messages are rendered
//       });
//     }
//   };

//   useEffect(() => {
//     if (ref.current && isUserAtBottom) {
//       ref.current.scrollTop = ref.current.scrollHeight; // Scroll to bottom on initial load or when user is at the bottom
//     }
//   }, [messagesAll]);

//   useEffect(() => {
//     const messageContainer = ref.current;
//     if (messageContainer) {
//       messageContainer.addEventListener('scroll', handleScroll);
//     }
//     return () => {
//       if (messageContainer) {
//         messageContainer.removeEventListener('scroll', handleScroll);
//       }
//     };
//   }, [loading, lastVisible, hasMoreMessages]);

//   useEffect(() => {
//     fetchMessages();
//   }, []);

//   let groupedMessages = [];
//   messagesAll.forEach((message) => {
//     const date = new Date(message.messageTime).toLocaleDateString();
//     if (!groupedMessages[date]) {
//       groupedMessages[date] = [];
//     }
//     groupedMessages[date].push(message);
//   });

//   return (
//     <>
//       <div className={`xl:w-[900px] lg:w-[900px] p-0`}>
//         <div className="flex justify-between items-center border-b pb-2 mb-5">
//           <h2 className="font-semibold">View Information</h2>
//           <div className="border cursor-pointer" onClick={() => setShow(false)}>
//             <MdClose size={25} />
//           </div>
//         </div>
//         <div id="message-container" className="max-h-[400px] relative overflow-auto" ref={ref}>

//           {(loading || isFetchingMore) && (
//             <div className="flex items-center justify-center pt-40">
//               <LoaderSpinner type="chatLoad" color="#FF8C00" />
//             </div>
//           )}
//           {Object.keys(groupedMessages).map((date) => (
//             <div key={date}>
//               <div className="flex justify-between items-center sticky top-0">
//                 <p className="border border-[#AEB2C0] opacity-50 w-full" />
//                 <p className="text-[12px] text-[#444444] w-[50%] text-center">{date}</p>
//                 <p className="border border-[#AEB2C0] opacity-50 w-full" />
//               </div>
//               {groupedMessages[date].map((message) => (
//                 <div key={message.id} className="flex flex-col overflow-x-auto">
//                   <div className="flex flex-col">
//                     <div className="grid grid-cols-12">
//                       {message?.senderId === list?.senderId ? (
//                         <div className="col-start-1 lg:col-end-8 md:col-end-8 col-end-13 p-3 rounded-lg">
//                           <div className="flex flex-row">
//                             <img
//                               src={list?.senderDetails?.userImage}
//                               alt="user"
//                               className="object-cover rounded-full w-[40px] h-[40px]"
//                             />
//                             <div className="relative ml-3 text-sm bg-white py-2 px-4 lg:w-auto w-full shadow rounded-xl">
//                               <div className="text-[#444444] text-[14px]">{message?.messageText}</div>
//                               <div className="flex justify-between items-center pt-2 pl-4">
//                                 <p className="text-[#9F9F9F] text-[12px]">
//                                   {moment(message?.messageTime).format("LT")}
//                                 </p>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       ) : (
//                         <div className="lg:col-start-6 md:col-start-6 col-start-1 col-end-13 p-3 rounded-lg">
//                           <div className="flex justify-start flex-row-reverse">
//                             <img
//                               src={list?.receiverDetails?.userImage}
//                               alt="user"
//                               className="object-cover rounded-full w-[40px] h-[40px]"
//                             />
//                             <div className="relative mr-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
//                               <div className="text-[#444444] text-[14px]">{message?.messageText}</div>
//                               <div className="flex justify-between items-center pt-2 pl-4">
//                                 <p className="text-[#9F9F9F] text-[12px]">
//                                   {moment(message?.messageTime).format("LT")}
//                                 </p>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           ))
//           }

//         </div>
//       </div >
//     </>
//   );
// };

// export default EditView;



import React, { useEffect, useState, useRef } from "react";
import { MdClose } from "react-icons/md";
import { collection, onSnapshot, query, orderBy, limit, startAfter } from "firebase/firestore";
import { db } from "../../../confiq/firebase";
import moment from "moment";
import LoaderSpinner from "../../../util/LoaderSpinner";

const EditView = ({ setShow, list }) => {
  const [lastVisible, setLastVisible] = useState(null);
  const [messagesAll, setMessagesAll] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const ref = useRef(null);
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);

  const fetchMessages = async (isScrollFetch = false) => {
    try {
      const messagesRef = collection(
        db,
        `Users/${list?.senderId}/Conversation/${list?.conversationId}/Messages`
      );

      let queryRef = query(messagesRef, orderBy('messageTime', 'desc'), limit(10));

      if (isScrollFetch && lastVisible) {
        queryRef = query(queryRef, startAfter(lastVisible));
      }

      const newUnsubscribe = onSnapshot(queryRef, (querySnapshot) => {
        const newMessages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (newMessages.length < 10) {
          setHasMoreMessages(false);
        }

        if (newMessages.length > 0) {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }

        setMessagesAll((prev) => {
          const existingIds = new Set(prev.map((msg) => msg.id));
          const filteredMessages = newMessages.reverse().filter((msg) => !existingIds.has(msg.id));
          return [...filteredMessages, ...prev];
        });

        setLoading(false);
        setIsFetchingMore(false);
      });

      return newUnsubscribe;
    } catch (error) {
      console.error('Error fetching messages:', error);
      setLoading(false);
      setIsFetchingMore(false);
    }
  };

  const handleScroll = (event) => {
    const messageContainer = event.target;
    const isAtBottom =
      messageContainer.scrollHeight - messageContainer.scrollTop === messageContainer.clientHeight;
    setIsUserAtBottom(isAtBottom);

    if (messageContainer.scrollTop === 0 && !isFetchingMore) {
      setIsFetchingMore(true);
      const currentScrollHeight = messageContainer.scrollHeight;

      fetchMessages(true).then(() => {
        // Restore the previous scroll position to prevent jumping
        setTimeout(() => {
          const newScrollHeight = messageContainer.scrollHeight;
          const scrollHeightDifference = newScrollHeight - currentScrollHeight;

          if (scrollHeightDifference > 0) {
            messageContainer.scrollTop = scrollHeightDifference; // Retain scroll position
          }
        }, 150); // Timeout to ensure new messages are rendered
      });
    }
  };

  useEffect(() => {
    if (ref.current && isUserAtBottom) {
      ref.current.scrollTop = ref.current.scrollHeight; // Scroll to bottom on initial load or when user is at the bottom
    }
  }, [messagesAll]);

  useEffect(() => {
    const messageContainer = ref.current;
    if (messageContainer) {
      messageContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (messageContainer) {
        messageContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading, lastVisible, hasMoreMessages]);

  useEffect(() => {
    fetchMessages();
  }, []);

  let groupedMessages = [];
  messagesAll.forEach((message) => {
    const date = new Date(message.messageTime).toLocaleDateString();
    if (!groupedMessages[date]) {
      groupedMessages[date] = [];
    }
    groupedMessages[date].push(message);
  });

  return (
    <>
      <div className={`xl:w-[900px] lg:w-[900px] p-0`}>
        <div className="flex justify-between items-center border-b pb-2 mb-5">
          <h2 className="font-semibold">View Information</h2>
          <div className="border cursor-pointer" onClick={() => setShow(false)}>
            <MdClose size={25} />
          </div>
        </div>
        <div id="message-container" className="max-h-[400px] relative overflow-auto" ref={ref}>
          {/* Anchor for smooth scroll */}
          {/* <div ref={scrollAnchorRef}></div> */}
          {(loading || isFetchingMore) && (
            <div className="flex items-center justify-center pt-40">
              <LoaderSpinner type="chatLoad" color="#FF8C00" />
            </div>
          )}
          {Object.keys(groupedMessages).map((date) => (
            <div key={date}>
              <div className="flex justify-between items-center sticky top-0">
                <p className="border border-[#AEB2C0] opacity-50 w-full" />
                <p className="text-[12px] text-[#444444] w-[50%] text-center">{date}</p>
                <p className="border border-[#AEB2C0] opacity-50 w-full" />
              </div>
              {groupedMessages[date].map((message) => (
                <div key={message.id} className="flex flex-col overflow-x-auto">
                  <div className="flex flex-col">
                    <div className="grid grid-cols-12">
                      {message?.senderId === list?.senderId ? (
                        <div className="col-start-1 lg:col-end-8 md:col-end-8 col-end-13 p-3 rounded-lg">
                          <div className="flex flex-row">
                            <img
                              src={list?.senderDetails?.userImage}
                              alt="user"
                              className="object-cover rounded-full w-[40px] h-[40px]"
                            />
                            <div className="relative ml-3 text-sm bg-white py-2 px-4 lg:w-auto w-full shadow rounded-xl">
                              <div className="text-[#444444] text-[14px]">{message?.messageText}</div>
                              <div className="flex justify-between items-center pt-2 pl-4">
                                <p className="text-[#9F9F9F] text-[12px]">
                                  {moment(message?.messageTime).format("LT")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="lg:col-start-6 md:col-start-6 col-start-1 col-end-13 p-3 rounded-lg">
                          <div className="flex justify-start flex-row-reverse">
                            <img
                              src={list?.receiverDetails?.userImage}
                              alt="user"
                              className="object-cover rounded-full w-[40px] h-[40px]"
                            />
                            <div className="relative mr-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
                              <div className="text-[#444444] text-[14px]">{message?.messageText}</div>
                              <div className="flex justify-between items-center pt-2 pl-4">
                                <p className="text-[#9F9F9F] text-[12px]">
                                  {moment(message?.messageTime).format("LT")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EditView;

