import React, { useState, useEffect } from "react";
import { MdSearch } from "react-icons/md";
import FlagChatList from "../../components/AdminComponents/flagChat/FlagChatList";
import { collection, doc, getDoc, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../../confiq/firebase";

const FlagChat = () => {

  // React hooks
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [unsubscribe, setUnsubscribe] = useState(null);

  const fetchMessages = async () => {
    try {
      setLoading(true);
      const flaggedMessagesQuery = query(
        collection(db, `FlaggedMessages/`)
      );
      const newUnsubscribe = onSnapshot(flaggedMessagesQuery, async (querySnapshot) => {
        const newMessages = await Promise.all(
          querySnapshot.docs.map(async (docSnapshot) => {
            const data = docSnapshot.data();
            const senderDocRef = doc(db, 'Users', data.senderId);
            const receiverDocRef = doc(db, 'Users', data.receiverId);
            const senderDoc = await getDoc(senderDocRef);
            const receiverDoc = await getDoc(receiverDocRef);
  
            return {
              id: docSnapshot.id,
              ...data,
              senderDetails: senderDoc.exists() ? senderDoc.data() : null,
              receiverDetails: receiverDoc.exists() ? receiverDoc.data() : null,
            };
          })
        );
  
        // Sort the messages based on the date field (assuming it is `date`)
        newMessages.sort((a, b) => new Date(b.messageTime) - new Date(a.messageTime));
        setMessages(newMessages);
        setLoading(false);
      });
      setUnsubscribe(() => newUnsubscribe);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setLoading(false);
    }
  };
  


  useEffect(() => {
    fetchMessages()
  }, [])



  return (
    <>

      {/* table component */}
      <div className="pt-10">
        <div className="flex  flex-wrap justify-between items-center">
          <h2 className="text-[20px] font-semibold leading-[20.16px]  text-[#444444]">
            Flagged Messages
          </h2>
          <div className="flex lg:py-0 py-2 ">
            <div className="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-full border-0 flex items-center px-4 lg:w-[380px] w-full py-3 h-[45px]">
              <MdSearch className="text-[25px] text-[#FF8C00]" />
              <input
                onChange={(e) => setSearch(e.target?.value)}
                type="text"
                className="focus:outline-none mx-2 w-full font-medium placeholder:text-[#ACACAC] placeholder:text-[16px]"
                value={search}
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <FlagChatList messages={messages} loading={loading} searchData={search} />
      </div>
    </>
  );
};

export default FlagChat;
